/* 체크박스 */
li.check-box {
    margin-bottom: 10px;
    display: flex;
}

label.check-box {
    position: relative;
    /*left: 80px;*/
    width: 100px;
    height: 40px;
    background-color: var(--white);
    box-shadow: 0 0 0 4px #303030;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

label.check-box input {
    appearance: none;
}

label.check-box span.checker {
    position: absolute;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--nav-color);
    transition-duration: 1s;
}

label.check-box input:checked ~ span.checker {
    left: initial;
    right: 35px;
    background-color: var(--nav-color);
    transition-duration: 1s;
    transform: translateX(35px);
    transform-origin: 35px 0;
}

@media screen and (max-width: 768px) {
    label.check-box input:checked ~ span.checker {
        left: initial;
        right: 60px;
        background-color: var(--nav-color);
        transition-duration: 1s;
        transform: translateX(60px);
        transform-origin: 40px 0;
    }
}

label span.text {
    position: absolute;
    background: transparent;
    left: 5px;
    color: var(--white);
    transition-duration: 1s;
}

label span.text:last-child {
    color: var(--black1);
    position: absolute;
    left: initial;
    right: 5px;
    transition-duration: 1s;
}

label.check-box input:checked ~ span.text {
    color: var(--black1);
    transition-duration: 1s;
}

label.check-box input:checked ~ span.text:last-child {
    color: var(--white);
    transition-duration: 1s;
}