@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700&family=Noto+Sans+KR:wght@400;500;700&display=swap');
@font-face {
    font-family: "Noto Sans KR";
    font-weight: 400;
    font-style: normal;
    /*src: url("/public/fonts/NotoSansKR-Regular.otf") format("opentype");*/
}
/*CKEditor와 디자인 충돌 방지*/
*:not(.ck > *) {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-weight: 400;
    font-style: normal;
    font-family: -apple-system, 'Noto Sans KR', sans-serif;
    /*font-display: fallback;*/
    /*background-color: #222222;*/
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
