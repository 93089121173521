.navigation-chapter {
    position: fixed;
    top: 0;
    left: var(--side-bar-book-name-close-width);
    width: var(--side-bar-chapter-close-width);
    height: 100%;
    background-color: var(--nav-background-color);
    border-left: 10px solid var(--nav-background-color);
    transition: 0.5s;
    overflow: hidden;
    overflow-y: scroll;
}

.navigation-chapter.active-all {
    left: var(--side-bar-book-name-open-width);
    width: var(--side-bar-chapter-open-width);
}

.navigation-chapter.active-book {
    left: var(--side-bar-book-name-open-width);
    width: var(--side-bar-chapter-close-width);
}

.navigation-chapter.active-chapter {
    left: var(--side-bar-book-name-close-width);
    width: var(--side-bar-chapter-open-width);
}

.navigation-chapter ul {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.navigation-chapter ul li {
    position: relative;
    width: 100%;
    list-style: none;
    border-radius: 15px;
    /*border-top-left-radius: 30px;*/
    /*border-bottom-left-radius: 30px;*/
}

.navigation-chapter ul li.hovered,
.navigation-chapter ul li:hover {
    background-color: var(--nav-color);
}

.navigation-chapter ul li span.clickable {
    position: relative;
    /*display: block;*/
    width: 100%;
    display: flex;
    text-decoration: none;
    color: var(--nav-color);
}

.navigation-chapter ul li.hovered span.clickable,
.navigation-chapter ul li:hover span.clickable {
    color: var(--white);
}

.navigation-chapter ul li span.clickable .icon {
    position: relative;
    display: block;
    min-width: 60px;
    line-height: 70px;
    text-align: center;
}

.navigation-chapter ul li span.clickable .icon ion-icon {
    font-size: 1.75em;
}

.navigation-chapter ul li span.clickable .title {
    position: relative;
    display: block;
    padding: 0 10px;
    height: 60px;
    line-height: 60px;
    text-align: start;
    white-space: nowrap;
    font-size: 1.6rem;
}


/* curve outside */
.navigation-chapter ul li.hovered span.clickable::before,
.navigation-chapter ul li:hover span.clickable::before {
    content: "";
    position: absolute;
    right: 0;
    top: -50px;
    width: 50px;
    height: 50px;
    /*background-color: transparent;*/
    border-radius: 50%;
    /*box-shadow: 35px 35px 0 10px var(--nav-color);*/
    pointer-events: none;
}

/*.navigation-chapter ul li.hovered a::after,*/
/*.navigation-chapter ul li:hover a::after {*/
/*  content: "";*/
/*  position: absolute;*/
/*  right: 0;*/
/*  bottom: -50px;*/
/*  width: 50px;*/
/*  height: 50px;*/
/*  background-color: transparent;*/
/*  border-radius: 50%;*/
/*  box-shadow: 35px -35px 0 10px var(--nav-color);*/
/*  pointer-events: none;*/
/*}*/

.redo {
    display: none;
}

@media screen and (max-width: 768px) {

    .navigation-chapter {
        left: 0;
        width: 0;
        height: 100%;
        transition: 0.5s;
        z-index: 9998;
    }

    .navigation-chapter.active-all,
    .navigation-chapter.active-book,
    .navigation-chapter.active-chapter {
        left: 50%;
        width: 35%;
        z-index: 9998;
        transition: 0.5s;
    }

    .redo {
        display: block;
    }
}