/* main */
.main {
    /*display: none;*/
    position: relative;
    width: calc(100vw - (var(--side-bar-book-name-close-width) + var(--side-bar-chapter-close-width)));
    max-width: 992px;
    left: 170px;
    min-height: 100vh;
    background-color: var(--background-color);
    color: var(--text-color);
    transition: 0.5s;
    margin-top: 1vh;
}

.main.active-all {
    /*left: 270px;*/
    --main-left: calc(var(--side-bar-book-name-open-width) + var(--side-bar-chapter-open-width));
    --main-width: calc(100vw - (var(--side-bar-book-name-open-width) + var(--side-bar-chapter-open-width)));
    /*left: calc(var(--side-bar-book-name-open-width) + var(--side-bar-chapter-open-width));*/
    left: var(--main-left);
    width: calc(100vw - (var(--side-bar-book-name-open-width) + var(--side-bar-chapter-open-width)));
}

.main.active-book {
    /*left: 250px;*/
    --main-left: calc(var(--side-bar-book-name-open-width) + var(--side-bar-chapter-close-width));
    --main-width: calc(100vw - (var(--side-bar-book-name-open-width) + var(--side-bar-chapter-close-width)));
    /*left: calc(var(--side-bar-book-name-open-width) + var(--side-bar-chapter-close-width));*/
    left: var(--main-left);
    width: calc(100vw - (var(--side-bar-book-name-open-width) + var(--side-bar-chapter-close-width)));
}

.main.active-chapter {
    /*left: 170px;*/
    --main-left: calc(var(--side-bar-book-name-close-width) + var(--side-bar-chapter-open-width));
    --main-width: calc(100vw - (var(--side-bar-book-name-open-width) + var(--side-bar-chapter-open-width)));
    left: var(--main-left);
    width: calc(100vw - (var(--side-bar-book-name-open-width) + var(--side-bar-chapter-open-width)));
}


@media screen and (max-width: 992px) {
    .main {
        /*width: calc(100vw - 260px);*/
        /*width: calc(100% - var(--main-left));*/
        /*max-width: 100vw;*/
        max-width: 992px;
    }
}
@media screen and (max-width: 768px) {
    .main {
        width: 100%;
        left: 0;
        padding: 10px;
        max-width: 100%;
    }

    .main.active-all {
        /*left: 80%;*/
        /*width: 20%;*/
        /*overflow-x: hidden;*/
        width: 100%;
        left: 0;
        padding: 10px;
        max-width: 100%;
    }

    .main.active-book {
        left: 0;
    }

    .main.active-chapter {
        left: 0;
    }
}