:root {

    --blue: #287bff;
    --white: #fff;
    --grey: #f5f5f5;
    --black1: #222;
    --black2: #999;

    --background-color: var(--white);
    --text-color: var(--black1);

    --old-book: #0470f8;
    --new-book: #a01c1e;
    /*--new-book: #ad1020;*/
    /*--old-book-background: #fafad2;*/
    --old-book-background: #f2f3f4;
    --new-book-background: #f2f3f4;
    --old-book-text: #fff;
    --new-book-text: #fff;

    --menu-bar: #f5f5f5;
    --sub-title: #8e44ad;
    --caption: #2e86c1;
    --highlight: #f9e79f;

    --side-bar-book-name-close-width: 80px;
    --side-bar-book-name-open-width: 150px;
    --side-bar-chapter-close-width: 100px;
    --side-bar-chapter-open-width: 100px;
}

.old-book {
    --nav-color: var(--old-book);
    --nav-color-text: var(--old-book-text);
    --nav-background-color: var(--old-book-background);
}

.new-book {
    --nav-color: var(--new-book);
    --nav-color-text: var(--new-book-text);
    --nav-background-color: var(--new-book-background);
}


body {
    min-height: 100vh;
    overflow-x: hidden;
}

.container {
    position: relative;
    width: 100%;
}

.Book {
    display: flex;
    flex-direction: row;
}

/* Hide scrollbar for Chrome, Safari and Opera */
[class|=navigation]::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
[class|=navigation] {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.clickable {
    cursor: pointer;
}
