#embed-container {
  padding: 1rem;

  div.word-list {
    position: unset;
    margin: 0 10px 0.5em 0.5em;

    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 1rem;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    /*overflow-y: scroll;*/

    &.new-book {
      --line-color: var(--new-color);
    }

    &.old-book {
      --line-color: var(--old-color);
    }

    h3 {
      text-align: center;
      font-size: 2rem;
      margin-top: 1rem;
      user-select: none;
    }

    ul li {
      margin: 0.5em 0 0.5em 0.5em;
      list-style: none;
      user-select: none;
      transition: 0.5s;

      &.word-item {
        display: flex;
        justify-content: space-between;
      }

      &.isHighlight > p span.word {
        background-color: #f7dc6f;
        border-radius: 5px;
        transition: background-color 0.5s ease-out;
      }

      &.selected {
        background-color: #c9c9c9;
        border-radius: 5px;
        transition: 0.5s;
      }

      p {
        text-decoration: none;
        color: var(--text-color);
        margin-left: 2em;
        text-indent: -2em;

        span.hidden {
          font-size: 0;
        }

        span.book {
          margin: 0.5em;
          color: var(--nav-color);
        }

        span.chapter {
          color: var(--line-color);
        }


        span.number {
          margin: 0.5em;
          color: var(--nav-color);
        }

        span.word {
          font-size: 1.1rem;

          span.sub-title {
            font-size: 0.9rem;
            color: var(--sub-title);
          }

          span.caption,
          sup.caption {
            font-size: 0.9rem;
            color: var(--caption);
          }

          span.highlight {
            background-color: var(--highlight);
          }
        }

        sup.subtitle-caption {
          font-size: 0.9rem;
          color: var(--sub-title);
          text-align: center;
        }

        span.subtitle {
          font-size: 1.5rem;
          color: var(--sub-title);
          text-align: center;

          span.subtitle-link:hover {
            text-decoration: underline;
          }
        }

        span.word {
          font-size: 1.1rem;

          span.caption-link:hover {
            text-decoration: underline;
          }
        }
      }
    }

    div li {
      margin: 0.5em 0 0.5em 0.5em;
      list-style: none;
      user-select: none;
      transition: 0.5s;

      &.selected {
        background-color: #c9c9c9;
        border-radius: 5px;
        transition: 0.5s;
      }

      p {
        text-decoration: none;
        color: var(--text-color);
        margin-left: 2em;
        text-indent: -2em;

        span.hidden {
          font-size: 0;
        }

        span.book {
          margin: 0.5em;
          color: var(--nav-color);
        }

        span.chapter {
          /*margin: 0.3em;*/
          color: var(--nav-color);
        }

        span.number {
          margin: 0.5em;
          color: var(--nav-color);
        }

        span.word {
          font-size: 1.1rem;

          span.sub-title {
            font-size: 0.9rem;
            color: var(--sub-title);
          }

          span.caption {
            font-size: 0.9rem;
            color: var(--caption);
          }

          span.highlight {
            background-color: var(--highlight);
          }
        }
      }
    }
  }

}
