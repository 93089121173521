.navigation-book {
    position: fixed;
    left: 0;
    top: 0;
    width: var(--side-bar-book-name-close-width);
    height: 100%;
    background-color: var(--nav-color);
    border-left: 10px solid var(--nav-color);
    transition: 0.5s;
    overflow: hidden;
    overflow-y: scroll;
}

.navigation-book.active-all,
.navigation-book.active-book {
    left: calc(var(--side-bar-book-name-close-width) * -1);
    width: 231px;
    /*width: 400px;*/
    z-index: 9999;
}

.navigation-book.active-chapter {
    left: 0;
    width: 70px;
}

.navigation-book ul {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.navigation-book ul li {
    position: relative;
    width: 100%;
    list-style: none;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.navigation-book ul li.hovered,
.navigation-book ul li:hover {
    background-color: var(--nav-background-color);
}

.navigation-book ul li:nth-child(1) {
    margin-bottom: 40px;
    /*pointer-events: none;*/
}
.navigation-book ul li:nth-child(1) span {
    height: 70px;
}
.navigation-book ul li:nth-child(1) span.icon {
    visibility: visible;
}

.navigation-book ul li:nth-child(1) span.icon span.version {
    position: relative;
    top: -3rem;
}
.navigation-book ul li:nth-child(1) span.clickable span:nth-child(3) {
    margin-top: 0.5rem;
}

.navigation-book ul li:nth-child(2) {
    margin-bottom: 40px;
    left: 0;
    transition-delay: 0.1s;
    transition: 0.5s;
}

.navigation-book.active-all ul li:nth-child(2),
.navigation-book.active-book ul li:nth-child(2) {
    left: 80px;
}

.navigation-book ul li span.clickable {
    position: relative;
    /*display: block;*/
    width: 100%;
    display: flex;
    text-decoration: none;
    color: var(--nav-color-text);
}

.navigation-book ul li.hovered span.clickable,
.navigation-book ul li:hover span.clickable {
    color: var(--nav-color);
}

.navigation-book ul li span.clickable .icon {
    position: relative;
    display: block;
    min-width: 60px;
    line-height: 70px;
    text-align: center;
}

.navigation-book ul li span.clickable .icon ion-icon {
    font-size: 1.75em;
}

.navigation-book ul li span.clickable .sub-title {
    position: relative;
    margin-right: 10px;
    display: block;
    min-width: 60px;
    line-height: 70px;
    text-align: center;
    font-size: 1.75em;
    font-weight: 700;
}

.navigation-book ul li span.clickable .title {
    position: relative;
    display: block;
    padding: 0 10px;
    height: 60px;
    line-height: 60px;
    text-align: start;
    white-space: nowrap;
    font-size: 1.3rem;
    font-weight: 500;
}


/* curve outside */
.navigation-book ul li.hovered span.clickable::before,
.navigation-book ul li:hover span.clickable::before {
    content: "";
    position: absolute;
    right: 0;
    top: -50px;
    width: 50px;
    height: 50px;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 35px 35px 0 10px var(--nav-background-color);
    pointer-events: none;
}

.navigation-book ul li.hovered span.clickable::after,
.navigation-book ul li:hover span.clickable::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: -50px;
    width: 50px;
    height: 50px;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 25px -25px 0 -3px var(--nav-background-color);
    pointer-events: none;
}

@media screen and (max-width: 768px) {
    .navigation-book {
        left: 0;
        width: 0;
        transition: 0.5s;
        z-index: 9999;
    }

    .navigation-book.active-all,
    .navigation-book.active-book,
    .navigation-book.active-chapter {
        left: -10%;
        width: 60%;
        z-index: 9999;
        transition: 0.5s;
    }

    .navigation-book ul li span.clickable .sub-title {
        visibility: hidden;
    }

    .navigation-book ul li:nth-child(1) span.icon {
        visibility: hidden;
    }
}