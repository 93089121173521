div.backdrop {
    background-color: #000;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9900;
    opacity: 0.5;
    transition: display 0.5s;
}

@media screen and (min-width: 769px) {
    div.backdrop {
        display: none;
    }
}