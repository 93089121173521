.top-bar {
    overflow: hidden;
    position: fixed;
    background-color: var(--menu-bar);
    top: 0;
    z-index: 2;
    width: calc(100% - 160px);
    /*max-width: 40vw;*/
    max-width: calc(100% - 160px);
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    border-bottom-right-radius: 10px;
}

.toggle {
    position: relative;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    font-size: 2.5em;
    cursor: pointer;
}

.toggle ion-icon {
    position: absolute;
    top: 5px;
    left: 10px;
    font-size: 1.2em;
}

.title {
    user-select: none;
}

.search {
    position: relative;
    width: 40%;
    margin: 0 10px;
}

.search label {
    position: relative;
    width: 100%;
}

.search label input {
    width: 100%;
    height: 40px;
    border-radius: 40px;
    padding: 5px 20px 5px 35px;
    font-size: 1.2rem;
    outline: none;
    border: 1px solid var(--black2);
}

.search label ion-icon {
    position: absolute;
    top: 0;
    left: 10px;
    font-size: 1.2em;
}

.top-bar-search-check {
    /*position: relative;*/
    width: 30%;
    margin: 0 10px;
    display: flex;
    flex-direction: row;
}
.top-bar-search-check div {
    display: flex;
    flex-direction: column;
}

.user {
    position: relative;
    width: 80px;
    height: 40px;
    /*border-radius: 50%;*/
    overflow: hidden;
    cursor: pointer;
}

.user ion-icon {
    position: absolute;
    top: 10px;
    left: 0;
    font-size: 1.4em;
}
.user ion-icon.setting {
    /*설정 완성 전까지 숨겨두기*/
    /*visibility: hidden;*/

    position: absolute;
    top: 10px;
    left: 30px;
    font-size: 1.4em;
}
.user ion-icon.user {
    position: absolute;
    top: 10px;
    left: 50px;
    font-size: 1.4em;
}

.user img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
}

@media screen and (max-width: 992px) {
    .top-bar {
        /*display: none;*/
        width: calc(100vw - 150px);
        /*width: calc(100vw - var(--main-left));*/
        /*max-width: 100vw;*/
        max-width: 992px;
    }
}
@media screen and (max-width: 768px) {
    .top-bar {
        width: 100%;
        left: 0;
        padding: 10px;
        max-width: 100%;
        border-bottom-right-radius: 0;
    }
}