#embed-container {
  padding: 1rem;
}
#embed-container div.word-list {
  position: unset;
  margin: 0 10px 0.5em 0.5em;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 1rem;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  /*overflow-y: scroll;*/
}
#embed-container div.word-list.new-book {
  --line-color: var(--new-color);
}
#embed-container div.word-list.old-book {
  --line-color: var(--old-color);
}
#embed-container div.word-list h3 {
  text-align: center;
  font-size: 2rem;
  margin-top: 1rem;
  user-select: none;
}
#embed-container div.word-list ul li {
  margin: 0.5em 0 0.5em 0.5em;
  list-style: none;
  user-select: none;
  transition: 0.5s;
}
#embed-container div.word-list ul li.word-item {
  display: flex;
  justify-content: space-between;
}
#embed-container div.word-list ul li.isHighlight > p span.word {
  background-color: #f7dc6f;
  border-radius: 5px;
  transition: background-color 0.5s ease-out;
}
#embed-container div.word-list ul li.selected {
  background-color: #c9c9c9;
  border-radius: 5px;
  transition: 0.5s;
}
#embed-container div.word-list ul li p {
  text-decoration: none;
  color: var(--text-color);
  margin-left: 2em;
  text-indent: -2em;
}
#embed-container div.word-list ul li p span.hidden {
  font-size: 0;
}
#embed-container div.word-list ul li p span.book {
  margin: 0.5em;
  color: var(--nav-color);
}
#embed-container div.word-list ul li p span.chapter {
  color: var(--line-color);
}
#embed-container div.word-list ul li p span.number {
  margin: 0.5em;
  color: var(--nav-color);
}
#embed-container div.word-list ul li p span.word {
  font-size: 1.1rem;
}
#embed-container div.word-list ul li p span.word span.sub-title {
  font-size: 0.9rem;
  color: var(--sub-title);
}
#embed-container div.word-list ul li p span.word span.caption,
#embed-container div.word-list ul li p span.word sup.caption {
  font-size: 0.9rem;
  color: var(--caption);
}
#embed-container div.word-list ul li p span.word span.highlight {
  background-color: var(--highlight);
}
#embed-container div.word-list ul li p sup.subtitle-caption {
  font-size: 0.9rem;
  color: var(--sub-title);
  text-align: center;
}
#embed-container div.word-list ul li p span.subtitle {
  font-size: 1.5rem;
  color: var(--sub-title);
  text-align: center;
}
#embed-container div.word-list ul li p span.subtitle span.subtitle-link:hover {
  text-decoration: underline;
}
#embed-container div.word-list ul li p span.word {
  font-size: 1.1rem;
}
#embed-container div.word-list ul li p span.word span.caption-link:hover {
  text-decoration: underline;
}
#embed-container div.word-list div li {
  margin: 0.5em 0 0.5em 0.5em;
  list-style: none;
  user-select: none;
  transition: 0.5s;
}
#embed-container div.word-list div li.selected {
  background-color: #c9c9c9;
  border-radius: 5px;
  transition: 0.5s;
}
#embed-container div.word-list div li p {
  text-decoration: none;
  color: var(--text-color);
  margin-left: 2em;
  text-indent: -2em;
}
#embed-container div.word-list div li p span.hidden {
  font-size: 0;
}
#embed-container div.word-list div li p span.book {
  margin: 0.5em;
  color: var(--nav-color);
}
#embed-container div.word-list div li p span.chapter {
  /*margin: 0.3em;*/
  color: var(--nav-color);
}
#embed-container div.word-list div li p span.number {
  margin: 0.5em;
  color: var(--nav-color);
}
#embed-container div.word-list div li p span.word {
  font-size: 1.1rem;
}
#embed-container div.word-list div li p span.word span.sub-title {
  font-size: 0.9rem;
  color: var(--sub-title);
}
#embed-container div.word-list div li p span.word span.caption {
  font-size: 0.9rem;
  color: var(--caption);
}
#embed-container div.word-list div li p span.word span.highlight {
  background-color: var(--highlight);
}

