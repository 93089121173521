.word-list {
    position: relative;
    top: 60px;
    margin: 0 10px 0.5em 0.5em;
    height: calc(100% - 60px - 10px);
    /*overflow-y: scroll;*/
}
#dialog .word-list {
    position: static;
}

.word-list ul li {
    margin: 0.5em 0 0.5em 0.5em;
    list-style: none;
    user-select: none;
    transition: 0.5s;
}
.word-list ul li.word-item {
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
}

.word-list ul li.isHighlight > p span.word {
    background-color: #f7dc6f;
    border-radius: 5px;
    transition: background-color 0.5s ease-out;
}
.word-list ul li > p span.word {
    transition: background-color 1s ease-out;
}
.word-list ul li.selected {
    background-color: #c9c9c9;
    border-radius: 5px;
    transition: 0.5s;
}

.word-list ul li p {
    text-decoration: none;
    color: var(--text-color);
    margin-left: 2rem;
    text-indent: -2rem;
}

.word-list ul li.word-item p:nth-child(2) {
    margin-left: 2rem;
    text-indent: 0;
    color: #495057;
    /*background-color: #2e86c1;*/
}

.word-list ul li p span.hidden {
    font-size: 0;
}
.word-list ul li p span.book {
    margin: 0.5rem;
    color: var(--nav-color);
}
.word-list ul li p span.chapter {
    /*margin: 0.3em;*/
    color: var(--nav-color);
}
.word-list ul li p span.number {
    margin: 0.5rem;
    color: var(--nav-color);
}

.word-list ul li p span.word {
    font-size: 1.1rem;
}

.word-list ul li p span.word {
    font-size: 1.1rem;
}
.word-list ul li.subtitle {
    font-size: 1.5rem;
    color: var(--sub-title);
    text-align: center;
}
.word-list ul li p span.word span.sub-title {
    font-size: 0.9rem;
    color: var(--sub-title);
}
.word-list ul li p span.subtitle {
    font-size: 1.5rem;
    color: var(--sub-title);
    text-align: center;
}
.word-list ul li p sup.subtitle-caption {
    font-size: 0.9rem;
    color: var(--sub-title);
    text-align: center;
}
.word-list ul li p span.subtitle span.subtitle-link:hover {
    text-decoration: underline;
}
.word-list ul li p span.word span.caption,
.word-list ul li p span.word sup.caption{
    font-size: 0.9rem;
    color: var(--caption);
}
.word-list ul li p span.word span.caption-link:hover {
    text-decoration: underline;
}
.word-list ul li p span.subtitle span.highlight,
.word-list ul li p span.word span.highlight {
    background-color: var(--highlight);
}

/*-----------------------------------------------*/
.word-list div li {
    margin: 0.5em 0 0.5em 0.5em;
    list-style: none;
    user-select: none;
    transition: 0.5s;
}

.word-list div li.selected {
    background-color: #c9c9c9;
    border-radius: 5px;
    transition: 0.5s;
}

.word-list div li p {
    text-decoration: none;
    color: var(--text-color);
    margin-left: 2em;
    text-indent: -2em;
}

.word-list div li p span.hidden {
    font-size: 0;
}
.word-list div li p span.book {
    margin: 0.5em;
    color: var(--nav-color);
}
.word-list div li p span.chapter {
    /*margin: 0.3em;*/
    color: var(--nav-color);
}
.word-list div li p span.number {
    margin: 0.5em;
    color: var(--nav-color);
}

.word-list div li p span.word {
    font-size: 1.1rem;
}

.word-list div li p span.word {
    font-size: 1.1rem;
}
.word-list div li p span.word span.sub-title {
    font-size: 0.9rem;
    color: var(--sub-title);
}
.word-list div li p span.word span.caption {
    font-size: 0.9rem;
    color: var(--caption);
}
.word-list div li p span.word span.highlight {
    background-color: var(--highlight);
}
